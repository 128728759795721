<template>
  <div class="main">
    <Head />
    <div class="help-content">
      <div class="help-box">
        <ul>
          <li>
            <div class="bottom problem">
              <div class="p_content">
                <p class="p_title title" style="text-align: center">
                  “拒绝网络谣言 清朗网络环境”倡议书
                </p>
                <p class="p_icon">
                  网络空间是亿万民众共同的精神家园。网络无疆，言论有界。网络谣言严重扰乱网络秩序，严重误导公共舆论，损害网民权益，污染网络环境。阻断谣言的传播链条，多方有责。为有效净化网络环境，依法打击网络谣言，上海域起网络科技有限公司向广大网民发起如下倡议：
                </p>
                <p class="p_icon">
                  一、强化法律意识，严守传播秩序。互联网不是“法外之地”，严格遵守国家法律法规、社会公德和伦理道德，坚决杜绝造谣、传谣等违法违规行为。
                </p>
                <p class="p_icon">
                  二、坚持文明上网，共建网络文明。树立正确的网络秩序观和安全观，明辨网上是非观念，文明使用网络语言，不造谣、不信谣、不传谣，争做网络文明使者。
                </p>
                <p class="p_icon">
                  三、提高防范意识，维护网络安全。通过权威官方平台了解网络信息，不轻信、不发布、不转发、不评论未经官方证实的网络信息，不断提升识谣、辨谣、防谣能力。
                </p>
                <p class="p_icon">
                  四、履行社会责任，行使监督权力。在关注社会热点、参与话题讨论的同时，如发现疑似谣言的不实信息，及时向平台或有关部门投诉或举报，全力遏制网络谣言滋生蔓延。
                </p>
                <p class="p_icon">
                  上海网警提示：互联网不是法外之地。我国相关法律法规都对打击网络谣言等违法犯罪行为作出了明确规定，违者需承担相应的民事、行政、刑事责任。对于编造、传播、散布谣言的行为，公安机关将依法严厉打击。请大家知法守法，不信谣、不传谣、不造谣，文明上网，理性发言，共同构建清朗网络空间。
                </p>
                <br>
                <br>
                <video src="https://gamedownload.gg.com/static/apk/testDeploy/resistrumors_mobile.mp4" controls autoplay loop />
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <Foot />
  </div>
</template>

<script>
import Head from '../modules/Head.vue'
import Foot from '../modules/Foot.vue'
export default {
  components: { Head, Foot }
}
</script>

<style scoped>
.help-content {
  background-color: #f5f5f5;
  display: flex;
  justify-content: center;
  align-items: center;
   padding-top:3.575rem;
}
.help-content .help-box {
  margin: 1.25rem 0;
  overflow: hidden;
  width: 35.625rem;
  background-color: #fff;
  padding: 0.8125rem;
  box-sizing: border-box;
  border-radius: 0.1875rem;
  border: 0.0625rem solid #eee;
}
.help-content .help-box li {
  background-color: #f5f5f5;
  border-radius: 0.1875rem;
  font-size: 0.5625rem;
  margin-bottom: 0.625rem;
}

.help-content .help-box li:last-of-type {
  margin-bottom: 0;
}

.help-content .help-box li .problem {
  padding: 0 1.25rem 0.625rem 1.25rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.help-content .help-box li .problem .content {
  padding: 0.625rem;
  margin-top: 0.625rem;
  background-color: #f5f5f5;
  border-radius: 0.1875rem;
  border: 0.0325rem solid #e7e7e7;
  font-size: 0.4375rem;
  display: flex;
  flex-direction: column;
  vertical-align: top;
}

.help-content .help-box li .bottom {
  border-top: 0.0325rem solid #eee;
  display: block;
}

.problem p {
  cursor: pointer;
}

.p_content .p_title {
  font-size: 0.5587rem;
  font-weight: 700;
  line-height: 1.5rem;
}

.p_content .p_icon {
  text-indent: 2em;
  line-height: 1.2rem;
  font-size: 0.4375rem;
}
video{
  width: 100%;
}
</style>
